import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/dashboard/SideBar";
import DashboardContent from "../components/dashboard/DashboardContent";
import BalancesContent from "../components/dashboard/BalancesContent";
import DepositContent from "../components/dashboard/DepositContent";
import WithdrawContent from "../components/dashboard/WithdrawContent";
import {
  mockCryptoBalanceList,
  withdrawDepositHistoryList,
} from "../constants/mock";
import DepositAndWithdrawalHistory from "../components/dashboard/DepositAndWithdrawalHistory";
import OpenOrdersContent from "../components/dashboard/OpenOrdersContent";
import OrderHistoryContent from "../components/dashboard/OrderHistoryContent";
import TradeHistoryContent from "../components/dashboard/TradeHistoryContent";
import { ACCOUNT_INFORMATION_URL } from "../constants/urls";
import Binance from "binance-api-node";

import Snackbar from "@mui/material/Snackbar";

import MuiAlert from "@mui/material/Alert";
import { useAuth } from "../provider/authProvider";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

const indexToTitle = {
  0: "Dashboard",
  1: "Balance",
  2: "Deposit",
  3: "Withdraw",
  4: "Deposit And Withdrawal History",
  5: "Open Orders",
  6: "Order History",
  7: "Trade History",
};

const client = Binance({
  apiKey: "",
  apiSecret: "",
});

const DashboardPage = () => {
  const { isUserAuthenticated, checkIfUserAuthenticated } = useAuth();

  const [accountInfo, setAccountInfo] = useState({});
  const [balanceMap, setBalanceMap] = useState({});

  const [btcToUSDTConversion, setBtcToUSDTConversion] = useState(null);

  const dropdownWalletItems = [
    "Balances",
    "Deposit",
    "Withdraw",
    "DepositAndWithdrawalHistory",
  ];

  const dropdownOrdersItems = ["Open Orders", "Order History", "Trade History"];

  const [activeIndex, setActiveIndex] = useState(0);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastContent, setToastContent] = useState("");

  useEffect(() => {
    checkIfUserAuthenticated();
    fetchAccountInfo();
    fetchTradeHistory();
  }, []);

  const renderContent = () => {
    const balances = accountInfo.balances ?? [];

    switch (indexToTitle[activeIndex]) {
      case "Dashboard":
        // code block
        return (
          <DashboardContent
            btcToUSDTConversion={btcToUSDTConversion}
            email="test@gmail.com"
            lastLogin={1549312452}
            userId="test123"
            ipAddress="12.123.123.gest"
          />
        );
      case "Balance":
        // code block
        return <BalancesContent cryptoBalanceList={mockCryptoBalanceList} />;

      case "Deposit":
        return <DepositContent balanceMap={balanceMap} />;

      case "Withdraw":
        return <WithdrawContent balanceMap={balanceMap} />;

      case "Deposit And Withdrawal History":
        return (
          <DepositAndWithdrawalHistory
            withdrawDepositHistoryList={withdrawDepositHistoryList}
          />
        );

      case "Open Orders":
        return <OpenOrdersContent onOrderCancelled={onOrderCancelled} />;
      case "Order History":
        return <OrderHistoryContent orderHistoryList={[]} />;

      case "Trade History":
        return <TradeHistoryContent tradeHistoryList={[]} />;

      default:
      // code block
    }
  };

  const onOrderCancelled = () => {
    setToastOpen(true);
    setToastContent("buy order has been cancelled");
  };

  const fetchAccountInfo = async () => {
    const bodyJson = {};

    let axiosConfig = {
      withCredentials: true,
    };

    const resp = await axios.post(
      ACCOUNT_INFORMATION_URL,
      bodyJson,
      axiosConfig
    );

    const newAccountInfo = resp.data;

    setAccountInfo(newAccountInfo);

    const newBalanceMap = {};
    let balances = newAccountInfo.balances;

    balances.forEach((balance) => {
      newBalanceMap[balance.asset] = balance;
    });

    setBalanceMap(newBalanceMap);
  };

  const fetchTradeHistory = async () => {
    const tradeHistory = await client.trades({
      symbol: "ETHUSDT",
      limit: 1,
    });

    setBtcToUSDTConversion(parseFloat(tradeHistory[0].price));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <div>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {toastContent}
          </Alert>
        </div>
      </Snackbar>

      <div>
        <div className="flex">
          <SideBar
            dropdownWalletItems={dropdownWalletItems}
            dropdownOrdersItems={dropdownOrdersItems}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            indexToTitle={indexToTitle}
          />

          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
