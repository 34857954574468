const tokenPairsMapping = {
  USDT: ["BTC"],
};

const CURRENTLY_SUPPORTED_NETWORK = ["BSC", "ETH"];
const DEFAULT_DEPOSIT_COIN = "USDT";
const DEFAULT_WITHDRAW_COIN = "USDT";

export {
  tokenPairsMapping,
  CURRENTLY_SUPPORTED_NETWORK,
  DEFAULT_DEPOSIT_COIN,
  DEFAULT_WITHDRAW_COIN,
};
