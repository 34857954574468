import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

import Header from "../pages/Header.tsx";

export const ProtectedRoute = () => {
  const { isUserAuthenticated } = useAuth();

  // Check if the user is authenticated
  if (!isUserAuthenticated) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
