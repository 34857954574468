import React from "react";

type Props = {
  token0: string;
  token1: string;
  longName: string;
  lastPrice: string;
  hour24ChangePrice: string;
  hour24HighPrice: string;
  hour24LowPrice: string;
  hour24VolumePrice: string;
  hour24OpenPrice: string;
};

const HomeStatus = React.memo(
  ({
    token0,
    token1,
    longName,
    lastPrice,
    hour24ChangePrice,
    hour24HighPrice,
    hour24LowPrice,
    hour24VolumePrice,
    hour24OpenPrice,
  }: Props) => {
    let token1Volume =
      parseFloat(hour24VolumePrice) * parseFloat(hour24OpenPrice);
    return (
      <div className="flex text-xs space-x-[50px]">
        <div>
          <div className="flex font mb-[6px]">
            <p className="text-[22px]">{token0}</p>
            <p className="text-[14px] text-normalGray">/{token1}</p>
          </div>
          <div>{longName}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">Last Price</div>
          <div>{lastPrice}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">24h Change</div>
          <div>{hour24ChangePrice}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">24h High</div>
          <div>{hour24HighPrice}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">24h Low</div>
          <div>{hour24LowPrice}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">{`24 volume (${token0})`}</div>
          <div>{hour24VolumePrice}</div>
        </div>

        <div>
          <div className="text-normalGray mb-[6px]">{`24 volume (${token1})`}</div>
          <div>{token1Volume.toFixed(2)}</div>
        </div>
      </div>
    );
  }
);

export default HomeStatus;
