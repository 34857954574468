import React, { useEffect, useRef, useState } from "react";
import Rating from "@mui/material/Rating";

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import axios from "axios";
import {
  GET_USER_LIKED_PAIRS,
  USER_LIKE_PAIRS,
  USER_UNLIKE_PAIRS,
} from "../constants/urls";
import { useAuth } from "../provider/authProvider";

type Props = {
  tokenPairsMapping: { [key: string]: [string] };
  selectedFilterToken: string;
  filterTickerStats: { [key: string]: TickerStatsType };
  prevfilterTickerStats: { [key: string]: TickerStatsType };
  onSelectPairs: ({
    token0,
    token1,
  }: {
    token0: string;
    token1: string;
  }) => void;
  onSelectMainToken: (mainToken: string) => void;
};

type TickerStatsType = {
  priceChangePercent: string;
  volume: string;
  lastPrice: string;
};

enum Mode {
  Change,
  Volume,
}

const TokenPairsFilter = React.memo(
  ({
    tokenPairsMapping,
    selectedFilterToken,
    filterTickerStats,
    prevfilterTickerStats,
    onSelectPairs,
    onSelectMainToken,
  }: Props) => {
    const { isUserAuthenticated, checkIfUserAuthenticated } = useAuth();

    const [mode, setMode] = useState<Mode>(Mode.Change);
    const [cryptoLikedPairs, setCryptoLikedPairs] = useState<string[]>([]);

    useEffect(() => {
      if (isUserAuthenticated) {
        fetchFavourites();
      } else {
        setCryptoLikedPairs([]);
      }
    }, [isUserAuthenticated]);

    let keys = Object.keys(tokenPairsMapping);

    let selectedPairs = tokenPairsMapping[selectedFilterToken];

    const handleLike = async (e, tokenPair) => {
      e.stopPropagation();

      const bodyJson = {
        cryptoPairLiked: tokenPair,
      };

      let axiosConfig = {
        withCredentials: true,
      };

      try {
        await axios.post(USER_LIKE_PAIRS, bodyJson, axiosConfig);

        let newCryptoLikedPairs = [...cryptoLikedPairs];
        newCryptoLikedPairs.push(tokenPair);

        setCryptoLikedPairs(newCryptoLikedPairs);
      } catch (e) {
        console.log("error like pair", e);
      }
    };

    const handleUnlike = async (e, tokenPair) => {
      e.stopPropagation();

      const bodyJson = {
        cryptoPairLiked: tokenPair,
      };

      let axiosConfig = {
        withCredentials: true,
      };

      try {
        await axios.post(USER_UNLIKE_PAIRS, bodyJson, axiosConfig);

        let newCryptoLikedPairs = [...cryptoLikedPairs];

        newCryptoLikedPairs = newCryptoLikedPairs.filter(
          (pair) => pair !== tokenPair
        );

        setCryptoLikedPairs(newCryptoLikedPairs);
      } catch (e) {
        console.log("error like pair", e);
      }
    };

    const handleStarLiked = (e, pairsToken, isPairLiked) => {
      if (isPairLiked) {
        handleUnlike(e, pairsToken);
      } else {
        handleLike(e, pairsToken);
      }
    };

    const fetchFavourites = async () => {
      try {
        let axiosConfig = {
          withCredentials: true,
        };

        const resp = await axios.post(GET_USER_LIKED_PAIRS, {}, axiosConfig);
        const likedArr = resp.data.map((token) => token.cryptoPairLiked);
        setCryptoLikedPairs(likedArr);
      } catch (e) {
        console.log("error like pair", e);
      }
    };

    return (
      <div className="flex-col ">
        <div className="flex bg-backgroundGray space-x-2 py-1 px-2">
          {keys.map((key) => {
            return (
              <div
                className={`px-[6px] py-[2px] text-xs border-[1px] cursor-pointer ${
                  selectedFilterToken === key
                    ? "border-greenSelected bg-backgroundShade text-greenSelected"
                    : ""
                }`}
                onClick={() => onSelectMainToken(key)}
                key={key}
              >
                {key}
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-between">
          <div className="relative py-1.5 px-1.5">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-3 h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="!outline-none cursor-text block py-[0.5px] w-[100px] pl-5 text-xs text-gray-900 border-normalBorder border-[1px]  bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            />
          </div>
          <div className="flex justify-center items-center">
            <input
              id="default-radio-1"
              type="radio"
              value="change"
              name="default-radio"
              checked={mode == Mode.Change}
              onChange={(e) => setMode(Mode.Change)}
              className="!outline-none cursor-pointer w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />

            <label className="ml-1 text-xs font-medium text-gray-900 dark:text-gray-300 mr-2">
              Change
            </label>

            <input
              id="default-radio-1"
              type="radio"
              value=""
              name="default-radio"
              checked={mode == Mode.Volume}
              className="!outline-none cursor-pointer w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => setMode(Mode.Volume)}
            />
            <label className="ml-1 text-xs font-medium text-gray-900 dark:text-gray-300 mr-2">
              Volume
            </label>
          </div>
        </div>
        <div className="relative max-h-[386px]" style={{ overflow: "scroll" }}>
          <table className="border-normalBorder" style={{ overflow: "scroll" }}>
            <thead className="sticky top-0 bg-white">
              <tr className="text-normalGray text-xs">
                <th className="w-[85px] font-medium">{`Pair`}</th>
                <th className="w-[93px] font-medium">{`Price`}</th>
                <th className="w-[100px] font-medium pr-[10px]">
                  {mode === Mode.Change ? "Change" : "Volume"}
                </th>
              </tr>
            </thead>

            <tbody className="">
              {selectedPairs.map((pair, index) => {
                let targetPair = pair + selectedFilterToken;
                const priceVolumeChangeInfo =
                  filterTickerStats[targetPair] ?? {};

                const prevPriceVolumeChangeInfo =
                  prevfilterTickerStats[targetPair] ?? {};

                let changeStyling = "";
                if (mode === Mode.Change) {
                  changeStyling =
                    parseFloat(priceVolumeChangeInfo.priceChangePercent) > 0
                      ? "text-normalGreen"
                      : "text-normalRed";
                }

                let priceColor = "";

                if (
                  parseFloat(priceVolumeChangeInfo.lastPrice) >
                  parseFloat(prevPriceVolumeChangeInfo.lastPrice)
                ) {
                  priceColor = "text-normalGreen";
                } else if (
                  parseFloat(priceVolumeChangeInfo.lastPrice) <
                  parseFloat(prevPriceVolumeChangeInfo.lastPrice)
                ) {
                  priceColor = "text-normalRed";
                }

                const volume = priceVolumeChangeInfo.volume
                  ? parseFloat(priceVolumeChangeInfo.volume).toFixed(2)
                  : "-";

                let pairsToken = `${pair}/${selectedFilterToken}`;

                let isPairLiked = cryptoLikedPairs.includes(pairsToken);
                return (
                  <tr
                    className="text-xs text-normalBlack hover:cursor-pointer hover:bg-backgroundGray"
                    onClick={() =>
                      onSelectPairs({
                        token0: pair,
                        token1: selectedFilterToken,
                      })
                    }
                    key={index}
                  >
                    <th>
                      <div className="flex items-center space-x-[3px]">
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleStarLiked(e, pairsToken, isPairLiked)
                          }
                        >
                          {isPairLiked ? (
                            <StarIcon style={{ width: 20, color: "#3ccb7c" }} />
                          ) : (
                            <StarOutlineIcon
                              style={{ width: 20, color: "#3ccb7c" }}
                            />
                          )}
                        </div>
                        <p>{pairsToken}</p>
                      </div>
                    </th>
                    <th
                      className={`text-right font-medium w-[93px] ${priceColor}`}
                    >
                      {priceVolumeChangeInfo.lastPrice ?? "-"}
                    </th>
                    <th
                      className={`text-right font-medium w-[100px] pr-[12px] ${changeStyling}`}
                    >
                      {mode === Mode.Change
                        ? `${
                            priceVolumeChangeInfo.priceChangePercent ?? "-"
                          }%`
                        : volume}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);

export default TokenPairsFilter;
