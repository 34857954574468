import {
  ExchangeInfo,
  OrderSide_LT,
  SymbolFilter,
  SymbolFilterType,
  SymbolMarketLotSizeFilter,
  SymbolPriceFilter,
} from "binance-api-node";
import { CURRENTLY_SUPPORTED_NETWORK } from "../constants/constants";
import { NetworkListDetail } from "../types/types";

const filterOptions = (options: NetworkListDetail[]): NetworkListDetail[] => {
  return options.filter((option) =>
    CURRENTLY_SUPPORTED_NETWORK.includes(option.network)
  );
};

const getExchangeInformationStats = (
  exchangeInfo: ExchangeInfo | undefined
): {
  priceFilter: SymbolPriceFilter | undefined;
  lotFilter: SymbolMarketLotSizeFilter | undefined;
} => {
  if (exchangeInfo == null) {
    return {
      priceFilter: undefined,
      lotFilter: undefined,
    };
  }
  let symbols = exchangeInfo.symbols || [{}];
  const symbolExchangeInfo = symbols[0] || {};

  let filters = symbolExchangeInfo.filters;

  let lotFilter: SymbolMarketLotSizeFilter | undefined = undefined;
  let notionalFilter: SymbolFilter | undefined = undefined;
  let priceFilter: SymbolPriceFilter | undefined = undefined;

  if (filters) {
    lotFilter = filters.find((filterInfo) => {
      return filterInfo.filterType === "LOT_SIZE";
    }) as SymbolMarketLotSizeFilter;

    notionalFilter = filters.find((filterInfo) => {
      return filterInfo.filterType === ("NOTIONAL" as SymbolFilterType);
    });

    priceFilter = filters.find((filterInfo) => {
      return filterInfo.filterType === ("PRICE_FILTER" as SymbolFilterType);
    }) as SymbolPriceFilter;
  }

  return {
    priceFilter,
    lotFilter,
  };
};

const findPrecision = (tickSize: string): number => {
  let tickSizeNum = parseFloat(tickSize);
  let precision = 0;

  while (tickSizeNum < 1) {
    precision++;
    tickSizeNum *= 10;
  }

  return precision;
};

export { filterOptions, getExchangeInformationStats, findPrecision };
