import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { createStyles, makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { REGISTER_ACTION_URL } from "../constants/urls";
import { useAuth } from "../provider/authProvider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import axios from "axios";
import { ERROR_CODE_USER_REGISTERED } from "../constants/errorCodes";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgba(0, 0, 0, 0.23)", // default
        },
        "&.Mui-focused fieldset": {
          border: "2px solid red", // focus
        },
      },
    },
  })
);

const RegisterPage = () => {
  const { checkIfUserAuthenticated } = useAuth();
  const navigate = useNavigate();

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastSeverity, setToastSeverity] = useState("");

  useEffect(() => {
    checkIfUserAuthenticated();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const bodyJson = {
      userName: email,
      email: email,
      password: password,
    };

    let axiosConfig = {
      withCredentials: true,
    };

    try {
      await axios.post(REGISTER_ACTION_URL, bodyJson, axiosConfig);

      navigateToHome();

      const isAuthenticated = await checkIfUserAuthenticated();

      if (isAuthenticated) {
        navigateToHome();
        setToastSeverity("success");
        setToastOpen(true);
        setToastContent("register has been successful");
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;

        if (status === ERROR_CODE_USER_REGISTERED) {
          setToastSeverity("error");
          setToastOpen(true);
          setToastContent(
            "the email you input has been registered please. Please use other email "
          );
        }
      }
    }
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const handleCheckChange = (event: any, newChecked: boolean) => {
    setChecked(newChecked);
  };

  const handleAlreadyRegistered = () => {
    navigate(`/login`);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastContent}
        </Alert>
      </Snackbar>
      <div className="flex justify-center h-[500px] items-center">
        <div>
          <h1 className="text-center text-xl mb-[40px]">Register</h1>
          <div
            style={{ boxShadow: "0 0 4px rgba(0,0,0,.2)", borderRadius: 5 }}
            className="pl-[30px] pr-[50px] py-[20px] items-center w-[450px]"
          >
            <div>
              <FormControl className="w-full" sx={{ m: 1 }} variant="standard">
                <TextField
                  className={classes.input + " w-full"}
                  id="standard-basic"
                  label={`Email Address`}
                  variant="standard"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl className="w-full" sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={handleCheckChange} />
                }
                label="I have read and agree to term of use"
                className="mt-[20px]"
              />

              <div
                style={{
                  background: "linear-gradient(90deg,#2a9662 0,#3dcd89 99.77%)",
                }}
                className={`cursor-pointer text-sm font-light text-white text-center py-[6px] mt-[10px] rounded-md`}
                onClick={handleSubmit}
              >
                {`Register`}
              </div>

              <div
                className={`cursor-pointer text-sm font-light text-greenGradientMostRight text-center py-[6px] mt-[10px] rounded-md`}
                onClick={handleAlreadyRegistered}
              >
                {`Already Registered?`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
