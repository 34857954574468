import React from "react";
import AskBidBookTable from "./AskBidBookTable";
import { BookOrderType, TradeHistoryDetail } from "../types/types";
import { Bid } from "binance-api-node";

type Props = {
  asksOrder: Bid[];
  bidsOrder: Bid[];
  token0Name: string;
  token1Name: string;
  tradeHistory: TradeHistoryDetail[];
};

const OrderBookTable = React.memo(
  ({ asksOrder, bidsOrder, token0Name, token1Name, tradeHistory }: Props) => {
    const lastTradeHistory = tradeHistory.length > 1 ? tradeHistory[0] : null;
    const secondLastTradehistory =
      tradeHistory.length > 3 ? tradeHistory[1] : null;

    const firstPriceFloat = parseFloat(lastTradeHistory?.price ?? "0") ?? 0;
    const secondPriceFloat =
      parseFloat(secondLastTradehistory?.price ?? "0") ?? 0;

    const renderStyle = () => {
      if (firstPriceFloat > secondPriceFloat) {
        return "text-normalGreen";
      } else if (secondPriceFloat > firstPriceFloat) {
        return "text-normalRed";
      } else {
        return "";
      }
    };

    return (
      <div className="border-[1.5px] border-normalBorder">
        <div className="h-[30px] bg-backgroundGray border-b-[1px] border-normalBorder"></div>
        <div className="flex">
          <AskBidBookTable
            askBidArr={asksOrder}
            token0Name={token0Name}
            token1Name={token1Name}
            bookOrderType={BookOrderType.Ask}
          />
        </div>

        <div className="h-[40px] bg-backgroundGray flex items-center">
          <p className={`text-md font-semibold ${renderStyle()}`}>
            {firstPriceFloat}
          </p>
        </div>

        <div className="flex">
          <AskBidBookTable
            askBidArr={bidsOrder}
            token0Name={token0Name}
            token1Name={token1Name}
            bookOrderType={BookOrderType.Bid}
          />
        </div>
      </div>
    );
  }
);

export default OrderBookTable;
