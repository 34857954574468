import React, { useEffect, useRef, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { SubmitInformation } from "../types/types";

type Props = {
  currentTradePrice: string;
  side: "buy" | "sell";
  token0Symbol: string;
  token1Symbol: string;
  balanceTitle: string;
  balance: string;
  basePrecision: number;
  quotePrecision: number;
  minInput?: string;
  maxInput?: string;
  minTotalInput?: string;
  maxTotalInput?: string;
  stepInput?: string;
  onSubmit: (SubmitInformation) => Promise<boolean>;
};

function roundClosestStep(number, step) {
  return Math.round(number / step) * step;
}

const BuySellSection = ({
  currentTradePrice,
  side,
  token0Symbol,
  token1Symbol,
  balanceTitle,
  balance,
  basePrecision,
  quotePrecision,
  minInput,
  maxInput,
  minTotalInput,
  maxTotalInput,
  stepInput,
  onSubmit,
}: Props) => {
  const perncentages = [0.25, 0.5, 0.75, 1.0];
  const title = side === "buy" ? "Buy" : "Sell";
  const [price, setPrice] = useState(currentTradePrice);
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setAmount("");
    setTotal("");
  }, [token0Symbol, token1Symbol]);

  useEffect(() => {
    setPrice(currentTradePrice);
  }, [currentTradePrice]);

  const handleSubmit = async () => {
    let shouldRefresh = await onSubmit({ price, amount, side });
    setIsLoading(false);
    if (shouldRefresh) {
      setAmount("");
      setTotal("");
    }
  };

  const handlePriceChange = (price) => {
    const priceStr = String(price);
    const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${quotePrecision}}$)`);
    const validated = priceStr.match(regex);
    if (validated) {
      setPrice(price);
      if (amount) {
        let total = parseFloat(price ?? 0) * parseFloat(amount ?? 0);
        setTotal(String(total ?? 0));
      }
    }
  };

  const handleAmountChange = (amount, byPassCheck = false) => {
    const amountStr = String(amount);
    const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${basePrecision}}$)`);
    const validated = amountStr.match(regex);
    if (validated || byPassCheck) {
      setAmount(amountStr);
      if (price) {
        let total = parseFloat(price ?? 0) * parseFloat(amountStr ?? 0);
        let truncatedTotal = total.toPrecision(quotePrecision);
        setTotal(String(truncatedTotal ?? 0));
      }
    }
  };

  const handleTotalChange = (total, byPassCheck = false) => {
    const totalStr = String(total);
    const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${basePrecision}}$)`);
    const validated = totalStr.match(regex);

    if (validated || byPassCheck) {
      setTotal(total);
      if (price) {
        let floatPrice = parseFloat(price) ?? 1;
        let amount = parseFloat(total) / floatPrice;

        let stepSizeTruncated = roundClosestStep(amount, stepInput);
        let truncatedAmount = stepSizeTruncated.toPrecision(basePrecision);
        setAmount(String(truncatedAmount ?? 0));
      }
    }
  };

  const handlePercentageClick = (percentage) => {
    if (side === "sell") {
      let newAmount = percentage * parseFloat(balance);
      let stepSizeTruncated = roundClosestStep(newAmount, stepInput);
      let truncatedAmount = stepSizeTruncated.toPrecision(basePrecision);
      handleAmountChange(truncatedAmount, true);
    } else {
      let newAmount = percentage * parseFloat(balance);
      let truncatedAmount = newAmount.toPrecision(quotePrecision);
      handleTotalChange(truncatedAmount, true);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    handleSubmit();
  };

  const isTotalAmountGreaterThanBalance = () => {
    if (side === "buy") {
      return parseFloat(total) > parseFloat(balance);
    } else {
      return parseFloat(amount) > parseFloat(balance);
    }
  };

  const shouldDisableSubmitButton = isTotalAmountGreaterThanBalance();

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex justify-between">
        <div className="font-semibold mb-[14px]">{`${title} ${token0Symbol}`}</div>
        <div className="flex text-xs">
          <AccountBalanceWalletIcon
            style={{ height: 16, width: 16 }}
            className="mr-[2px]"
          />
          <p className="mr-[2px]">{parseFloat(balance)}</p>
          <p>{balanceTitle}</p>
        </div>
      </div>

      <div className="flex flex-col space-y-[0px]">
        <div className="flex justify-between">
          <div className="text-xs">Price: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center mb-[10px]">
            <input
              ref={inputRef}
              value={price}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={(e) => handlePriceChange(e.target.value)}
              type="number"
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token1Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs">Amount: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center mb-[10px]">
            <input
              value={amount}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              min={minInput}
              max={maxInput}
              step={stepInput}
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token0Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs"></div>
          <div className="w-[256px] justify-between space-x-[4px] flex flex-wrap relative items-center mb-[10px]">
            {perncentages.map((percentage, index) => {
              return (
                <div
                  className="bg-white border-[1px] border-normalBorder text-sm flex-1 text-center cursor-pointer"
                  onClick={() => handlePercentageClick(percentage)}
                  key={index}
                >
                  {percentage * 100}%
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs">Total: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center">
            <input
              min={minTotalInput}
              max={maxTotalInput}
              step={"0.00000000001"}
              value={total}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={(e) => handleTotalChange(e.target.value)}
              type="number"
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token1Symbol}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <input
          value={
            isLoading ? `Submitting ${title} order` : `${title} ${token0Symbol}`
          }
          type="submit"
          disabled={shouldDisableSubmitButton || isLoading}
          style={{
            backgroundColor:
              shouldDisableSubmitButton || isLoading ? "#999999" : "",
          }}
          className={`border-2 ${
            side === "buy" ? "bg-normalGreen" : "bg-normalRed"
          } text-white text-sm font-light text-center py-[6px] mt-[30px] cursor-pointer w-full`}
        />
      </div>
    </form>
  );
};

export default BuySellSection;
