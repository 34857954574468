import { SymbolMarketLotSizeFilter, SymbolPriceFilter } from 'binance-api-node'
import moment from 'moment'
import React from 'react'
import { findPrecision } from '../utils/utils'

type Props = {
  histories: [TxHistory]
  priceFilter: SymbolPriceFilter | undefined
  lotFilter: SymbolMarketLotSizeFilter | undefined
  token0: string
  token1: string
  maxHeight: number
  className: string
}

type TxHistory = {
  qty: string
  price: string
  time: number
}

const TradeHistory = React.memo(
  ({ histories, priceFilter, lotFilter, token0, token1, className }: Props) => {
    const priceTickSize = priceFilter?.tickSize
    const lotStepSize = lotFilter?.stepSize

    return (
      <div
        className={className}
        style={{ overflow: 'scroll' }}
      >
        <table
          className=' border-normalBorder w-full'
          style={{ overflow: 'scroll' }}
        >
          <thead>
            <tr className='text-normalGray text-xs'>
              <th>{`Price (${token1})`}</th>
              <th>{`Amount (${token0})`}</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {histories.map(({ qty, price, time }, index) => {
              const momentTimestamp = moment(time)
              let strTime = momentTimestamp.format('MM-DD HH:mm:ss')

              let isCurrentPriceDecrease = false
              if (index !== histories.length - 1) {
                let previousHistory = histories[index + 1]
                isCurrentPriceDecrease = price < previousHistory.price
              }

              let formattedPrice = price

              if (priceTickSize && priceTickSize !== '0') {
                const precision = findPrecision(priceTickSize)

                formattedPrice = parseFloat(price).toFixed(precision)
              }

              let formattedQty = qty

              if (lotStepSize && lotStepSize !== '0') {
                const precision = findPrecision(lotStepSize)

                formattedQty = parseFloat(qty).toFixed(precision)
              }

              return (
                <tr
                  className='text-xs text-normalBlack'
                  key={index}
                >
                  <td
                    className={`${
                      isCurrentPriceDecrease
                        ? 'text-normalRed'
                        : 'text-normalGreen'
                    } text-center`}
                  >
                    {formattedPrice}
                  </td>
                  <td className='text-right font-medium w-[93px]'>
                    {formattedQty}
                  </td>
                  <td className='text-right font-medium w-[100px]'>
                    {strTime}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
)

export default TradeHistory
