import React, { useEffect, useRef, useState } from "react";
import Binance from "binance-api-node";
import OrderBookTable from "../components/OrderBookTable";
import HomeStatus from "../components/HomeStatus";
import TokenPairsFilter from "../components/TokenPairsFilter";
import { tokenPairsMapping } from "../constants/futuresContants";
import TradeHistory from "../components/TradeHistory";

import FuturesExchangeSection from "../components/FuturesExchangeSection";
import OpenOrderSection from "../components/OpenOrderSection";
import MyOrderHistorySection from "../components/MyOrderHistory";
import moment from "moment";
import {
  ACCOUNT_INFORMATION_URL,
  ALL_ORDERS_URL,
  FUTURES_GET_ALL_ORDERS,
  OPEN_ORDERS_URL,
} from "../constants/urls";
import Snackbar from "@mui/material/Snackbar";

import MuiAlert from "@mui/material/Alert";
import CustomizedDialogs from "../components/common/CustomDialog";

import { useAuth } from "../provider/authProvider";
import TradingViewWidget from "../components/TradingViewWidget/TradingViewWidget";
import { getExchangeInformationStats } from "../utils/utils";
import FuturesTradingViewWidget from "../components/TradingViewWidget/FuturesTradingViewWidget";
import FuturesTablesSection from "../components/Tables/FuturesTablesSection";
import axios from "axios";

const client = Binance({
  apiKey: "",
  apiSecret: "",
  httpBase: "https://testnet.binance.vision",
});

const longNameMapping = {
  ETH: "Ethereum",
  BTC: "Bitcoin",
  BNB: "Binance",
};

const ORDER_BOOK_LENGTH = 8;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FuturesPage = () => {
  const { isUserAuthenticated, checkIfUserAuthenticated } = useAuth();
  const [asksOrder, setAsksOrder] = useState([]);
  const [bidsOrder, setBidsOrder] = useState([]);
  const [ticketStats, setTickerStats] = useState({});
  const [tradeHistory, setTradeHistory] = useState([]);

  const [activeTokenPairs, setActiveTokenPairs] = useState({
    token0: "BTC",
    token1: "USDT",
  });

  const [filterTickerStats, setFilterTickerStats] = useState({});
  const [prevFilterTickerStats, setPrevFilterTickerStats] = useState({});
  const [selectedFilterToken, setSelectedFilterToken] = useState("USDT");

  const [openOrders, setOpenOrders] = useState([]);

  const [order24HoursHistoryList, setOrder24HoursHistoryList] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});
  const [currentAskPrice, setCurrentAskPrice] = useState("0");
  const [currentBidPrice, setCurrentBidPrice] = useState("0");

  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const [filteredCombination, setFilteredCombination] = useState([]);

  const [exchangeInfo, setExchangeInfo] = useState(undefined);

  const [
    triggerListeningFilterDailyStats,
    setTriggerListeningFilterDailyStats,
  ] = useState(false);

  const wsDepth = useRef();
  const wsTickerStats = useRef();
  const wsTradeHistory = useRef();
  const allTickerStats = useRef();

  useEffect(() => {
    checkIfUserAuthenticated();
  }, [checkIfUserAuthenticated]);

  useEffect(() => {
    fetchAccountInfo();
    fetchQueryOrders();
  }, []);

  useEffect(() => {
    const listOfSelectedCombination = tokenPairsMapping[selectedFilterToken];
    // i.e. ["BNBAAVE", "BNBETH", etc]
    const filteredCombination = [];

    for (const cryptoPair of listOfSelectedCombination) {
      filteredCombination.push(cryptoPair + selectedFilterToken);
    }

    setFilteredCombination(filteredCombination);
    getAllTickerStats(filteredCombination);
  }, [selectedFilterToken]);

  useEffect(() => {
    listenFilterDailyStats();
  }, [triggerListeningFilterDailyStats]);

  useEffect(() => {
    setExchangeInfo(undefined);
    setTradeHistory([]);
    setBidsOrder([]);
    setAsksOrder([]);
    setTickerStats({});

    getInitialOrderBook();
    getExchangeInfo();
    getDailyStats();
    startWebSocketEvents();
    getInitialTradeHistory();
    fetchOpenOrders();
    getMy24OrdersHistory();
  }, [activeTokenPairs]);

  const getExchangeInfo = async () => {
    let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;
    const exchangeInfo = await client.futuresExchangeInfo({ symbol: symbol });

    setExchangeInfo(exchangeInfo);
  };

  const fetchQueryOrders = async () => {
    const resp = await axios.get(FUTURES_GET_ALL_ORDERS, {
      withCredentials: true,
    });

    console.log("resp ", resp.data);
  };

  const startWebSocketEvents = async () => {
    if (wsDepth.current) {
      setAsksOrder([]);
      setBidsOrder([]);
      wsDepth.current();
    }
    if (wsTickerStats.current) {
      wsTickerStats.current();
    }

    let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;
    wsDepth.current = client.ws.futuresPartialDepth(
      { symbol: symbol, level: 20 },
      (depth) => {
        const { bidDepth, askDepth } = depth;

        let reversedAsks = askDepth.reverse();

        setAsksOrder(reversedAsks.slice(0, ORDER_BOOK_LENGTH));
        setBidsOrder(bidDepth.slice(0, ORDER_BOOK_LENGTH));
      }
    );

    wsTickerStats.current = client.ws.futuresTicker(symbol, (ticker) => {
      setTickerStats(ticker);
    });
  };

  const getInitialOrderBook = async () => {
    let orderBook = await client.futuresBook({ symbol: "BTCUSDT", limit: 20 });
    let asks = orderBook.asks;
    let bids = orderBook.bids;
    setAsksOrder(asks.slice(0, ORDER_BOOK_LENGTH));
    setBidsOrder(bids.slice(0, ORDER_BOOK_LENGTH));
  };

  const getDailyStats = async () => {
    let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;

    let dailyStats = await client.futuresDailyStats({ symbol: symbol });

    const {
      openPrice,
      highPrice,
      lowPrice,
      volume,
      priceChange,
      askPrice,
      bidPrice,
    } = dailyStats;

    setCurrentBidPrice(bidPrice);
    setCurrentAskPrice(askPrice);
    setTickerStats({
      open: openPrice,
      priceChange: priceChange,
      high: highPrice,
      low: lowPrice,
      volume: volume,
    });
  };

  const getMy24OrdersHistory = async () => {
    try {
      let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;

      const resp = await axios.post(
        ALL_ORDERS_URL,
        {
          symbol: symbol,
          startTime: String(moment().subtract(23, "hours").valueOf()),
          endTime: String(moment().valueOf()),
        },
        { withCredentials: true }
      );

      const data = await resp.data;

      if (Array.isArray(data)) {
        setOrder24HoursHistoryList(data.reverse());
      }
    } catch (e) {
      console.log("error FE happen ", e);
    }
  };

  const fetchOpenOrders = async () => {
    try {
      let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;

      const resp = await axios.post(
        OPEN_ORDERS_URL,
        { symbol: symbol },
        { withCredentials: true }
      );

      const data = await resp.data;

      setOpenOrders(data);
    } catch (e) {
      console.log("error FE happen ", e);
    }
  };

  const getAllTickerStats = async (filteredCombination) => {
    let timer = null;

    let filterStats = {};

    for (const cryptoCombination of filteredCombination) {
      // eslint-disable-next-line no-loop-func

      client
        .futuresDailyStats({ symbol: cryptoCombination })
        .then((tickerInfo) => {
          filterStats[cryptoCombination] = tickerInfo;
          clearTimeout(timer);
          timer = setTimeout(() => {
            setFilterTickerStats(filterStats);
            setTriggerListeningFilterDailyStats(
              !triggerListeningFilterDailyStats
            );
          }, 0);
        });
    }
  };

  const getInitialTradeHistory = async () => {
    let currentTokenPairs = activeTokenPairs.token0 + activeTokenPairs.token1;
    let history = await client.futuresTrades({
      symbol: currentTokenPairs,
      limit: 30,
    });

    setTradeHistory(history.reverse());
    listenToTradeHistory(currentTokenPairs);
  };

  const listenToTradeHistory = async (tokenPair) => {
    if (wsTradeHistory.current) {
      wsTradeHistory.current();
    }

    wsTradeHistory.current = client.ws.futuresAggTrades(
      [tokenPair],
      (trade) => {
        setTradeHistory((prevState) => {
          let newArr = [
            { qty: trade.quantity, price: trade.price, time: trade.tradeTime },
            ...prevState,
          ];
          return newArr.slice(0, 100);
        });
      }
    );
  };

  const fetchAccountInfo = async () => {
    try {
      const bodyJson = {};

      let axiosConfig = {
        withCredentials: true,
      };

      const resp = await axios.post(
        ACCOUNT_INFORMATION_URL,
        bodyJson,
        axiosConfig
      );
      const data = await resp.data;

      setAccountInfo(data);
    } catch (e) {
      console.log("error FE happen ", e);
    }
  };

  const listenFilterDailyStats = async () => {
    if (allTickerStats.current) {
      allTickerStats.current();
    }

    let timerFlag = null;

    allTickerStats.current = client.ws.futuresAllTickers((tickers) => {
      if (timerFlag === null) {
        let currentFilter = {};

        for (const combination of filteredCombination) {
          currentFilter[combination] = filterTickerStats[combination];
        }

        for (const ticker of tickers) {
          if (!filteredCombination.includes(ticker.symbol)) {
            continue;
          }

          currentFilter[ticker.symbol] = {
            ...ticker,
            lastPrice:
              parseFloat(ticker.open) > 0
                ? ticker.open
                : currentFilter[ticker.symbol].lastPrice,
          };
        }

        setFilterTickerStats((previousValue) => {
          setPrevFilterTickerStats(previousValue);
          return currentFilter;
        });

        timerFlag = setTimeout(() => {
          timerFlag = null;
        }, 1000);
      }
    });
  };

  const handleSelectTokenPairs = ({ token0, token1 }) => {
    setTickerStats({});
    setActiveTokenPairs({ token0: token0, token1: token1 });
  };

  const onSelectMainToken = (mainToken) => {
    setFilterTickerStats({});
    allTickerStats.current.value = null;
    setSelectedFilterToken(mainToken);
  };

  const onOrderSubmitted = (side) => {
    const toastContent =
      side === "buy"
        ? "buy order has been submitted"
        : "sell order has been submitted";
    setToastContent(toastContent);

    setToastOpen(true);
    fetchOpenOrders();
    getMy24OrdersHistory();
    fetchAccountInfo();
  };

  const onOrderCancelled = (toastContent) => {
    setToastOpen(true);
    setToastContent(toastContent);
    fetchOpenOrders();
    getMy24OrdersHistory();
    fetchAccountInfo();
  };

  const balances = accountInfo.balances ?? [];

  const [toastOpen, setToastOpen] = useState(false);
  const [toastContent, setToastContent] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  const handleWarningDialog = (status) => {
    setIsWarningDialogOpen(status);
  };

  const handleNotEnabledFunctions = () => {
    setIsWarningDialogOpen(true);
  };

  const curSymbol = activeTokenPairs.token0 + activeTokenPairs.token1;

  const { priceFilter, lotFilter } = getExchangeInformationStats(exchangeInfo);

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {toastContent}
        </Alert>
      </Snackbar>
      <CustomizedDialogs
        open={isWarningDialogOpen}
        setOpen={handleWarningDialog}
      />

      <div className="flex pt-[35px] h-[692px] mb-[5px]">
        <div className="w-[1070px] h-full mb-[35px] px-[16px] flex flex-col">
          <div className="mb-[20px] h-[50px]">
            <HomeStatus
              token0={activeTokenPairs.token0}
              token1={activeTokenPairs.token1}
              longName={longNameMapping[activeTokenPairs.token1]}
              lastPrice={ticketStats.open}
              hour24ChangePrice={ticketStats.priceChange}
              hour24HighPrice={ticketStats.high}
              hour24LowPrice={ticketStats.low}
              hour24VolumePrice={ticketStats.volume}
              hour24OpenPrice={ticketStats.open}
            />
          </div>

          <div className="h-[535px] w-full bg-grey">
            <FuturesTradingViewWidget symbol={curSymbol} />
          </div>

          <div className=""></div>
        </div>
        <div className="mr-[10px]">
          <OrderBookTable
            asksOrder={asksOrder}
            bidsOrder={bidsOrder}
            token0Name={activeTokenPairs.token0}
            token1Name={activeTokenPairs.token1}
            tradeHistory={tradeHistory}
          />
          <div>
            <TradeHistory
              priceFilter={priceFilter}
              lotFilter={lotFilter}
              histories={tradeHistory}
              token0={activeTokenPairs.token0}
              token1={activeTokenPairs.token1}
              className="relative max-h-[200px] border-2 border-normalBorder"
            />
          </div>
        </div>
        <div className="w-[256px]">
          <FuturesExchangeSection
            currentAskTradePrice={currentAskPrice}
            currentBidTradePrice={currentBidPrice}
            token0={activeTokenPairs.token0}
            token1={activeTokenPairs.token1}
            balances={balances}
            exchangeInfo={exchangeInfo || {}}
            onOrderSubmitted={onOrderSubmitted}
            handleNotEnabledFunctions={handleNotEnabledFunctions}
          />
        </div>
      </div>

      <div>
        <FuturesTablesSection />
      </div>
    </>
  );
};

export default FuturesPage;
