import React from "react";
import EmptyImage from "../assets/no_record_image.png";

import moment from "moment";
import { CANCEL_ALL_ORDER_URL, CANCEL_ORDER_URL } from "../constants/urls";
import { Order, OrderSide_LT } from "binance-api-node";
import axios from "axios";

type Props = {
  openOrders: Order[];
  title: React.ReactNode;
  height: number;
  token1: string;
  symbol: string;
  onOrderCancelled: (toastContent: string) => void;
};

const OpenOrderSection = React.memo(
  ({ symbol, openOrders, title, height, token1, onOrderCancelled }: Props) => {
    const handleCancelOrder = async (
      side: OrderSide_LT,
      targetSymbol: string,
      orderId: number
    ) => {
      const resp = await axios.post(
        CANCEL_ORDER_URL,
        {
          symbol: targetSymbol,
          orderId: String(orderId),
        },
        { withCredentials: true }
      );

      const result = resp.data;

      if (result) {
        let toastContent =
          side === "BUY"
            ? "buy order has been cancelled"
            : "sell order has been cancelled";
        onOrderCancelled(toastContent);
      }
    };

    const handleCancelAll = async () => {
      let openSymbols = openOrders.map((order) => order.symbol);

      let uniqueOpenSymbols = [...new Set(openSymbols)];

      uniqueOpenSymbols.forEach(async (openSymbol) => {
        const resp = await axios.post(
          CANCEL_ALL_ORDER_URL,
          {
            symbol: openSymbol,
          },
          { withCredentials: true }
        );

        const result = resp.data;

        if (result) {
          onOrderCancelled("all order has been cancelled");
        }
      });
    };

    return (
      <div className="flex flex-col" style={{ height: height }}>
        {title}

        <div
          className="border-normalBorder border-[1px] h-[267px] flex flex-col flex-1"
          style={{ overflow: "scroll" }}
        >
          <div className="" style={{ overflow: "scroll" }}>
            <table className="flex-1" style={{ position: "sticky" }}>
              <thead
                className="bg-backgroundGray border-normalBorder"
                style={{ position: "sticky" }}
              >
                <tr className="text-normalGray text-xs text-left ">
                  <th className="py-[5px] px-[6px] w-[160px] font-medium">{`Date`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[10px] px-[6px] w-[77.93px] font-medium">{`Pair`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[87.12px] font-medium">{`Type`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[82.67px] font-medium">{`Side`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[89.31px] font-medium">{`Price`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[118.25px] font-medium">{`Amount`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[126.65px] font-medium">{`Filled(%)`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[135.52px] font-medium">{`Total`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[213.27px] font-medium">{`Trigger Conditions`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[317.5px] font-medium">
                    <div
                      className="inline ml-[30px] px-[15px] py-[px] border-[1px] border-borderDarkDray cursor-pointer bg-white"
                      onClick={() => handleCancelAll()}
                    >
                      Cancel all
                    </div>
                  </th>
                </tr>
              </thead>
              {openOrders.length > 0 && (
                <tbody
                  className="border-collapse w-full"
                  style={{ overflow: "scroll" }}
                >
                  {openOrders.map((order) => {
                    const {
                      orderId,
                      transactTime,
                      symbol,
                      type,
                      side,
                      price,
                      origQty,
                      executedQty,
                    } = order;

                    const momentTimestamp = moment(transactTime);
                    let strTime = momentTimestamp.format("MM-DD HH:mm:ss");
                    return (
                      <tr className="text-xs text-left">
                        <th className="py-[10px] px-[4px] font-medium">{`${strTime}`}</th>
                        <th className="py-[10px] px-[4px] font-medium">{`${symbol}`}</th>
                        <th className="py-[10px] px-[4px] font-medium">{`${type}`}</th>
                        <th
                          className={`py-[10px] px-[4px] font-medium ${
                            side == "BUY"
                              ? "text-normalGreen"
                              : "text-normalRed"
                          }`}
                        >{`${side}`}</th>
                        <th className="py-[10px] px-[4px] font-medium">{`${price}`}</th>
                        <th className="py-[10px] px-[4px] font-medium">{`${origQty}`}</th>
                        <th className="py-[10px] px-[4px] font-medium">{`${
                          parseFloat(executedQty) / parseFloat(origQty)
                        }`}</th>
                        <th className="py-[10px] px-[4px] font-medium">
                          {`${
                            parseFloat(price) * parseFloat(origQty)
                          } ${token1}`}
                        </th>
                        <th className="py-[10px] px-[4px] font-medium">{`-`}</th>
                        <th className="py-[10px] px-[4px] font-medium flex justify-center items-center">
                          {
                            <div
                              className="inline px-[15px] border-[1px] border-borderDarkDray cursor-pointer"
                              onClick={() =>
                                handleCancelOrder(side, symbol, orderId)
                              }
                            >
                              Cancel
                            </div>
                          }
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>

          {openOrders.length === 0 && (
            <div className="flex justify-center flex-1">
              <div className="flex flex-col justify-center align-center">
                <div className="flex justify-center">
                  <img src={EmptyImage} alt="empty image" />
                </div>
                <div>No records found</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default OpenOrderSection;
