import React, { useEffect, useRef, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { SubmitInformation } from "../types/types";

import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type Props = {
  currentTradePrice: string;
  side: "buy" | "sell";
  token0Symbol: string;
  token1Symbol: string;
  balanceTitle: string;
  balance: string;
  basePrecision: number;
  quotePrecision: number;
  minInput?: string;
  maxInput?: string;
  minTotalInput?: string;
  maxTotalInput?: string;
  stepInput?: string;
  onSubmit: (SubmitInformation) => Promise<boolean>;
};

function roundClosestStep(number, step) {
  return Math.round(number / step) * step;
}

const FuturesBuySellSection = React.memo(
  ({
    currentTradePrice,
    side,
    token0Symbol,
    token1Symbol,
    balanceTitle,
    balance,
    basePrecision,
    quotePrecision,
    minInput,
    maxInput,
    minTotalInput,
    maxTotalInput,
    stepInput,
    onSubmit,
  }: Props) => {
    const perncentages = [0.25, 0.5, 0.75, 1.0];
    const title = side === "buy" ? "Buy" : "Sell";
    const [price, setPrice] = useState(currentTradePrice);
    const [amount, setAmount] = useState("");
    const [total, setTotal] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      setAmount("");
      setTotal("");
    }, [token0Symbol, token1Symbol]);

    useEffect(() => {
      setPrice(currentTradePrice);
    }, [currentTradePrice]);

    const handleSubmit = async () => {
      let shouldRefresh = await onSubmit({ price, amount, side });
      setIsLoading(false);
      if (shouldRefresh) {
        setAmount("");
        setTotal("");
      }
    };

    const handlePriceChange = (price) => {
      const priceStr = String(price);
      const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${quotePrecision}}$)`);
      const validated = priceStr.match(regex);
      if (validated) {
        setPrice(price);
        if (amount) {
          let total = parseFloat(price ?? 0) * parseFloat(amount ?? 0);
          setTotal(String(total ?? 0));
        }
      }
    };

    const handleAmountChange = (amount, byPassCheck = false) => {
      const amountStr = String(amount);
      const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${basePrecision}}$)`);
      const validated = amountStr.match(regex);
      if (validated || byPassCheck) {
        setAmount(amountStr);
        if (price) {
          let total = parseFloat(price ?? 0) * parseFloat(amountStr ?? 0);
          let truncatedTotal = total.toPrecision(quotePrecision);
          setTotal(String(truncatedTotal ?? 0));
        }
      }
    };

    const handleTotalChange = (total, byPassCheck = false) => {
      const totalStr = String(total);
      const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${basePrecision}}$)`);
      const validated = totalStr.match(regex);

      if (validated || byPassCheck) {
        setTotal(total);
        if (price) {
          let floatPrice = parseFloat(price) ?? 1;
          let amount = parseFloat(total) / floatPrice;

          let stepSizeTruncated = roundClosestStep(amount, stepInput);
          let truncatedAmount = stepSizeTruncated.toPrecision(basePrecision);
          setAmount(String(truncatedAmount ?? 0));
        }
      }
    };

    const handlePercentageClick = (percentage) => {
      if (side === "sell") {
        let newAmount = percentage * parseFloat(balance);
        let stepSizeTruncated = roundClosestStep(newAmount, stepInput);
        let truncatedAmount = stepSizeTruncated.toPrecision(basePrecision);
        handleAmountChange(truncatedAmount, true);
      } else {
        let newAmount = percentage * parseFloat(balance);
        let truncatedAmount = newAmount.toPrecision(quotePrecision);
        handleTotalChange(truncatedAmount, true);
      }
    };

    const handleFormSubmit = (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      handleSubmit();
    };

    const isTotalAmountGreaterThanBalance = () => {
      if (side === "buy") {
        return parseFloat(total) > parseFloat(balance);
      } else {
        return parseFloat(amount) > parseFloat(balance);
      }
    };

    const shouldDisableSubmitButton = isTotalAmountGreaterThanBalance();

    return (
      <form onSubmit={handleFormSubmit} className="px-[20px] w-full">
        <div className="flex justify-between">
          <p className="mb-[10px]">{`Available 1000t ${token1Symbol}`}</p>
        </div>

        <div className="flex flex-col space-y-[10px]">
          <TextField
            className="w-full"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>Price</div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start" style={{ paddingLeft: 10 }}>
                  <div>USDT</div>
                </InputAdornment>
              ),
              inputProps: { min: 0, max: 10 },
            }}
          />

          <TextField
            className="w-full"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>Size</div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start" style={{ paddingLeft: 10 }}>
                  <div>USDT</div>
                </InputAdornment>
              ),
              inputProps: { min: 0, max: 10 },
            }}
          />
        </div>
        <div className="flex justify-center">
          <input
            value={isLoading ? `Submitting Buy order` : `Buy/Long`}
            type="submit"
            disabled={shouldDisableSubmitButton || isLoading}
            style={{
              backgroundColor:
                shouldDisableSubmitButton || isLoading ? "#999999" : "",
            }}
            className={`border-2 bg-normalGreen text-white text-sm font-light text-center py-[6px] mt-[30px] cursor-pointer w-full`}
          />

          <input
            value={isLoading ? `Submitting Sell order` : `Sell/Short`}
            type="submit"
            disabled={shouldDisableSubmitButton || isLoading}
            style={{
              backgroundColor:
                shouldDisableSubmitButton || isLoading ? "#999999" : "",
            }}
            className={`border-2 bg-normalRed text-white text-sm font-light text-center py-[6px] mt-[30px] cursor-pointer w-full`}
          />
        </div>

        <div></div>
      </form>
    );
  }
);

export default FuturesBuySellSection;
