import React, { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PositionTable from "./PositionTable";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#c99400",
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#c99400",
    },
  },
});

type Props = {};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const FuturesTablesSection = React.memo(({}: Props) => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="future table tabs"
          className={classes.tabs}
        >
          <Tab label="Positions" />
          <Tab label="Open Orders" />
          <Tab label="Order History" />
          <Tab label="Trade History" />
          <Tab label="Transaction History" />
          <Tab label="Position History" />
          <Tab label="Assets" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PositionTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        Item Three
      </CustomTabPanel>
    </div>
  );
});

export default FuturesTablesSection;
