import React, { useEffect, useRef, useState } from 'react'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { SubmitInformation } from '../types/types'

type Props = {
  currentTradePrice: string
  side: 'buy' | 'sell'
  token0Symbol: string
  token1Symbol: string
  balanceTitle: string
  balance: string
  totalPrecision: number
  onSubmit: (SubmitInformation) => void
}

const MarketSection = ({
  currentTradePrice,
  side,
  token0Symbol,
  token1Symbol,
  balanceTitle,
  balance,
  onSubmit,
  totalPrecision,
}: Props) => {
  const title = side === 'buy' ? 'Buy' : 'Sell'
  const [price, setPrice] = useState(currentTradePrice)
  const [amount, setAmount] = useState('')
  const [total, setTotal] = useState('')
  const inputRef = useRef(null)
  const perncentages = [0.25, 0.5, 0.75, 1.0]

  useEffect(() => {
    setPrice(currentTradePrice)
  }, [currentTradePrice])

  useEffect(() => {
    const total = parseFloat(price ?? 0) * parseFloat(amount ?? 0)

    setTotal(String(total ?? 0))
  }, [price, amount])

  const handleSubmit = () => {
    onSubmit({ price, amount: total, side })
  }

  const handlePriceChange = (price) => {
    setPrice(price)
  }

  const handleTotalChange = (total) => {
    const amountStr = String(amount)
    const regex = new RegExp(`^(\\d*\\.{0,1}\\d{0,${totalPrecision}}$)`)
    const validated = amountStr.match(regex)
    if (validated) {
      setTotal(total)
    }
  }

  const handlePercentageClick = (percentage) => {
    let totalPrice = percentage * parseFloat(balance)
    let truncatedTotalPrice = totalPrice.toPrecision(totalPrecision)
    handleTotalChange(truncatedTotalPrice.toString())
  }

  return (
    <div className=''>
      <div className='flex justify-between'>
        <div className='font-semibold mb-[14px]'>{`${title} ${token0Symbol}`}</div>
        <div className='flex text-xs'>
          <AccountBalanceWalletIcon
            style={{ height: 16, width: 16 }}
            className='mr-[2px]'
          />
          <p className='mr-[2px]'>{parseFloat(balance)}</p>
          <p>{balanceTitle}</p>
        </div>
      </div>

      <div className='flex flex-col space-y-[6px]'>
        <div className='flex justify-between'>
          <div className='text-xs'>Price: </div>
          <div className='w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center mb-4'>
            <input
              ref={inputRef}
              className=' text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none'
              onChange={(e) => handlePriceChange(e.target.value)}
              disabled={true}
              type='number'
              placeholder='Market'
            />
            <div className='flex'>
              <span className='text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600'>
                {token1Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className='flex justify-between mb-[10px]'>
          <div className='text-xs'>Total: </div>
          <div className='w-[256px] border-[1px] border-normalBorder flex flex-wrap relative bg-white items-center'>
            <input
              value={total}
              className='text-sm leading-normal border-0 px-3 relative self-center outline-none'
              onChange={(e) => handleTotalChange(e.target.value)}
              type='number'
            />
            <div className='flex'>
              <span className='text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600'>
                {side === 'buy' ? token1Symbol : token0Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='text-xs'></div>
          <div className='w-[256px] justify-between space-x-[4px] flex flex-wrap relative items-center mb-[10px]'>
            {perncentages.map((percentage, idx) => {
              return (
                <div
                  key={idx}
                  className='bg-white border-[1px] border-normalBorder text-sm flex-1 text-center cursor-pointer'
                  onClick={() => handlePercentageClick(percentage)}
                >
                  {percentage * 100}%
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div
        className={`border-2 ${
          side === 'buy' ? 'bg-normalGreen' : 'bg-normalRed'
        } text-white text-sm font-light text-center py-[6px] mt-[30px] cursor-pointer`}
        onClick={handleSubmit}
      >
        {`${title} ${token0Symbol}`}
      </div>
    </div>
  )
}

export default MarketSection
