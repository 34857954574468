const PREFIX =
  process.env.REACT_APP_MODE === "DEBUG" ? process.env.REACT_APP_API_URL ?? 'http://localhost:5001' : "";

const COINS_INFO_URL = `${PREFIX}/coin-infos`;

const DEPOSIT_ADDRESS_URL = `${PREFIX}/deposit-address`;

const DEPOSIT_HISTORY_URL = `${PREFIX}/deposit-history`;
const WITHDRAW_HISTORY_URL = `${PREFIX}/withdraw-history`;

const PROTECTED_URL = `${PREFIX}/api/users/protected`;
const LOGOUT_URL = `${PREFIX}/api/users/logout`;
const LOGIN_ACTION_URL = `${PREFIX}/api/users/login`;
const REGISTER_ACTION_URL = `${PREFIX}/api/users/signup`;
const ACCOUNT_INFORMATION_URL = `${PREFIX}/api/users/get-account-info`;
const USER_ASSETS_URL = `${PREFIX}/api/users/user-assets`;

const IS_USER_AUTHENTICATED = `${PREFIX}/is-user-authenticated`;
const USER_LIKE_PAIRS = `${PREFIX}/api/favourites/add-favourite-pair`;
const USER_UNLIKE_PAIRS = `${PREFIX}/api/favourites/remove-favourite-pair`;
const GET_USER_LIKED_PAIRS = `${PREFIX}/api/favourites/get-favourite-pair`;

const OPEN_ORDERS_URL = `${PREFIX}/api/orders/open-orders`;
const ALL_ORDERS_URL = `${PREFIX}/api/orders/all-orders`;
const CANCEL_ORDER_URL = `${PREFIX}/api/orders/cancel-order`;
const CANCEL_ALL_ORDER_URL = `${PREFIX}/api/orders/cancel-all-order`;
const NEW_ORDER_URL = `${PREFIX}/api/orders/new-order`;
const MY_TRADES_URL = `${PREFIX}/api/orders/my-trades`;

const FUTURES_GET_ALL_ORDERS = `${PREFIX}/get-all-orders`;

export {
  CANCEL_ORDER_URL,
  CANCEL_ALL_ORDER_URL,
  OPEN_ORDERS_URL,
  MY_TRADES_URL,
  COINS_INFO_URL,
  ALL_ORDERS_URL,
  DEPOSIT_ADDRESS_URL,
  USER_ASSETS_URL,
  ACCOUNT_INFORMATION_URL,
  NEW_ORDER_URL,
  DEPOSIT_HISTORY_URL,
  WITHDRAW_HISTORY_URL,
  LOGIN_ACTION_URL,
  REGISTER_ACTION_URL,
  PROTECTED_URL,
  LOGOUT_URL,
  IS_USER_AUTHENTICATED,
  USER_LIKE_PAIRS,
  USER_UNLIKE_PAIRS,
  GET_USER_LIKED_PAIRS,
  FUTURES_GET_ALL_ORDERS,
};
