import React, { useCallback, useEffect, useState } from "react";
import FuturesBuySellSection from "./FuturesBuySellSection";
import { BalanceDetail, SubmitInformation } from "../types/types";
import {
  ExchangeInfo,
  OrderSide_LT,
  SymbolFilter,
  SymbolFilterType,
  SymbolMarketLotSizeFilter,
} from "binance-api-node";
import { NEW_ORDER_URL } from "../constants/urls";
import { ENABLE_FUNCTIONS } from "../configs/configs";
import MarketSection from "./MarketSection";
import axios from "axios";

type Props = {
  currentAskTradePrice: string;
  currentBidTradePrice: string;
  token0: string;
  token1: string;
  balances: BalanceDetail[];
  onOrderSubmitted: (type: OrderSide_LT) => void;
  handleNotEnabledFunctions: () => void;
  exchangeInfo: ExchangeInfo;
};

type MarketType = "LIMIT" | "MARKET";

const FuturesExchangeSection = React.memo(
  ({
    currentAskTradePrice,
    currentBidTradePrice,
    token0,
    token1,
    balances,
    exchangeInfo,
    onOrderSubmitted,
    handleNotEnabledFunctions,
  }: Props) => {
    const [balanceObject, setBalanceObject] = useState<{
      [x in string]: BalanceDetail;
    }>({});

    useEffect(() => {
      const balanceObject = {};

      for (const balance of balances) {
        balanceObject[balance.asset] = balance;
      }

      setBalanceObject(balanceObject);
    }, [balances]);

    const [marketType, setMarketType] = useState<MarketType>("LIMIT");

    const token0Balance = balanceObject[token0] ?? null;
    const token1Balance = balanceObject[token1] ?? null;

    const handleSubmit = useCallback(
      async (submitInfo: SubmitInformation): Promise<boolean> => {
        if (!ENABLE_FUNCTIONS) {
          handleNotEnabledFunctions();
          return false;
        }

        try {
          const resp = await axios.post(
            NEW_ORDER_URL,
            {
              symbol: token0 + token1,
              side: submitInfo.side.toUpperCase(),
              type: "LIMIT",
              price: submitInfo.price,
              quantity: submitInfo.amount,
              timeInForce: "GTC",
            },
            { withCredentials: true }
          );

          const data = resp.data;

          if (data) {
            onOrderSubmitted(submitInfo.side);
            return true;
          }

          return false;
        } catch (e) {
          console.log("error ", e);
          return false;
        }
      },
      []
    );

    const handleMarketSubmit = async (submitInfo: SubmitInformation) => {
      if (!ENABLE_FUNCTIONS) {
        handleNotEnabledFunctions();
        return;
      }

      try {
        const additionalParam: any =
          submitInfo.side.toUpperCase() === "BUY"
            ? { quoteOrderQty: submitInfo.amount }
            : { quantity: submitInfo.amount };

        const resp = await fetch(
          `${NEW_ORDER_URL}?` +
            new URLSearchParams({
              symbol: token0 + token1,
              side: submitInfo.side.toUpperCase(),
              type: "MARKET",
              ...additionalParam,
            }),
          {
            method: "POST",
          }
        );

        const data = await resp.json();

        if (data) {
          onOrderSubmitted(submitInfo.side);
        }
      } catch (e) {
        console.log("error ", e);
      }
    };

    let symbols = exchangeInfo.symbols || [{}];
    const symbolExchangeInfo = symbols[0] || {};

    let filters = symbolExchangeInfo.filters;

    let lotFilter: SymbolMarketLotSizeFilter | undefined = undefined;
    let notionalFilter: SymbolFilter | undefined = undefined;

    if (filters) {
      lotFilter = filters.find((filterInfo) => {
        return filterInfo.filterType === "LOT_SIZE";
      }) as SymbolMarketLotSizeFilter;

      notionalFilter = filters.find((filterInfo) => {
        return filterInfo.filterType === ("NOTIONAL" as SymbolFilterType);
      });
    }

    let minTotalInput: string | undefined = undefined;
    let maxTotalInput: string | undefined = undefined;

    let minQty = lotFilter?.minQty || "0.000000000001";
    let maxQty = lotFilter?.maxQty || "10000000000000";
    let stepSize = lotFilter?.stepSize || "0.00001";

    if (notionalFilter?.applyMinToMarket) {
      minTotalInput = notionalFilter?.minNotional;
    }

    if (notionalFilter?.applyMaxToMarket) {
      maxTotalInput = notionalFilter?.maxNotional;
    }

    const handleClickLimit = () => {
      setMarketType("LIMIT");
    };

    const handleClickMarket = () => {
      setMarketType("MARKET");
    };

    return (
      <div className="border-[1px] border-normalBorder">
        <div className="text-sm font-small py-[6px] px-[12px] border-b-[1px] border-normalBorder">
          Exchange
        </div>
        <div>
          <div className="text-sm px-[12px] py-[6px] flex space-x-[4px] bg-backgroundGray border-b-[1px] border-normalBorder">
            <div
              onClick={() => handleClickLimit()}
              className={`cursor-pointer bg-white px-[6px] border-[1px] border-normalBorder ${
                marketType === "LIMIT"
                  ? "border-greenSelected bg-backgroundShade text-greenSelected"
                  : ""
              }`}
            >
              Limit
            </div>
            <div
              onClick={() => handleClickMarket()}
              className={`cursor-pointer bg-white px-[6px] border-[1px] border-normalBorder ${
                marketType === "MARKET"
                  ? "border-greenSelected bg-backgroundShade text-greenSelected"
                  : ""
              }`}
            >
              Market
            </div>
          </div>

          {marketType === "LIMIT" ? (
            <div className="flex">
              <FuturesBuySellSection
                currentTradePrice={currentBidTradePrice}
                side="buy"
                token0Symbol={token0}
                token1Symbol={token1}
                balance={token1Balance ? token1Balance.free : "0"}
                balanceTitle={token1}
                onSubmit={handleSubmit}
                basePrecision={symbolExchangeInfo.baseAssetPrecision}
                quotePrecision={symbolExchangeInfo.quoteAssetPrecision}
                minInput={minQty}
                maxInput={maxQty}
                stepInput={stepSize}
                minTotalInput={minTotalInput}
                maxTotalInput={maxTotalInput}
              />
            </div>
          ) : (
            <MarketSection
              currentTradePrice={currentBidTradePrice}
              side="buy"
              token0Symbol={token0}
              token1Symbol={token1}
              balance={token1Balance ? token1Balance.free : "0"}
              balanceTitle={token1}
              onSubmit={handleMarketSubmit}
              totalPrecision={symbolExchangeInfo.quoteAssetPrecision}
            />
          )}
        </div>
      </div>
    );
  }
);

export default FuturesExchangeSection;
